<template>
  <div>
    <el-row :gutter="15" style="min-height:calc(100vh - 130px)" class="flex">
      <el-col :span="16">
        <div class="add gd" style="height:100%;">
          <el-card class="add-top f18 bd">监控案件手动导入</el-card>
          <el-card style="flex:1" class="mt-20">
            <div class="additem-title f14">
              <span>专利信息</span>
            </div>
            <div class="flex-j-c mt-30">
              <el-form
                ref="form"
                :model="form"
                :rules="rules"
                hide-required-asterisk
                label-width="82px"
              >
                <el-form-item label="专利类型" prop="service_id">
                  <el-select class="w-320" v-model="form.service_id" placeholder="选择">
                    <el-option label="外观专利" value="1"></el-option>
                    <el-option label="实用新型" value="2"></el-option>
                    <el-option label="发明专利" value="3"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item prop="name" label="专利名称">
                  <el-input class="w-320" v-model="form.name" placeholder="请输入专利名称"></el-input>
                </el-form-item>
                <el-form-item prop="patent_code" label="专利号">
                  <el-input class="w-320" v-model="form.patent_code" placeholder="请输入专利号"></el-input>
                </el-form-item>
                <el-form-item prop="patent_date_app" label="申请日">
                  <el-date-picker
                    type="date"
                    placeholder="选择申请日期"
                    v-model="form.patent_date_app"
                    class="w-320"
                    value-format="yyyy-MM-dd"
                  ></el-date-picker>
                </el-form-item>
                <el-form-item prop="percents" label="费减比例">
                  <el-select class="w-320" v-model="form.percents">
                    <el-option label="无费减" value="无费减"></el-option>
                    <el-option label="70%" value="70%"></el-option>
                    <el-option label="85%" value="85%"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="费减至" prop="years">
                  <el-select class="w-320" v-model="form.years" placeholder="请选择">
                    <el-option v-for="item in sum" :key="item" :label="item+'年'" :value="item"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="年费已缴至" prop="annual_fee_by_time">
                  <el-date-picker
                    type="year"
                    placeholder="请选择年费已缴至哪一年（该年前不生成）"
                    v-model="form.annual_fee_by_time"
                    class="w-320"
                    value-format="yyyy"
                  ></el-date-picker>
                </el-form-item>
                <el-form-item label="费减受理日" prop="cost_deduction_time">
                  <el-date-picker
                    type="date"
                    placeholder="费减受理日"
                    v-model="form.cost_deduction_time"
                    class="w-320"
                    value-format="yyyy-MM-dd"
                  ></el-date-picker>
                </el-form-item>
                 <el-form-item  label="代理事务所">
                  <el-input class="w-320" v-model="form.agency_office_name" placeholder="请输入代理事务所名称"></el-input>
                </el-form-item>
                <el-form-item label="办理登记日">
                  <el-date-picker
                    type="date"
                    placeholder="办理登记通知书发文日期"
                    v-model="form.check_in_time"
                    class="w-320"
                    value-format="yyyy-MM-dd"
                  ></el-date-picker>
                </el-form-item>
                <el-form-item>
                  <el-button class="bg-b" @click="onSubmit">提交</el-button>
                  <el-button class="bg-b" @click="addCancl">返回</el-button>
                </el-form-item>
              </el-form>
            </div>
          </el-card>
        </div>
      </el-col>
      <el-col :span="8">
        <div style="height: 100%;" class="gd">
           <monitor></monitor>
        <infor style="flex:1"></infor>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import monitor from "./common/monitor";
import infor from "./common/Infor";
export default {
  components: {
    monitor,
    infor
  },
  methods: {
    onSubmit() {
      this.$refs.form.validate((boolean, object) => {
        if (boolean) {
          let data={...this.form}
          if(data.percents==''){
            data.percents='3'
          }
          if(data.percents=='无费减'){
            data.percents=''
          }
          this.$request
            .post("/client/patent/manually", { ...data,is_trusteeship:1 })
            .then(res => {
            });
        }
      });
    },
    addCancl() {
      this.$router.go(-1);
    }
  },
  created() {},
  data() {
    return {
      form: {
        service_id: "",
        patent_code: "",
        name: "",
        patent_date_app: "",
        percents: "",
        years: "",
        annual_fee_by_time: "",
        cost_deduction_time:"",
        agency_office_name:"",
        check_in_time:""
      },
      rules: {
        service_id: [
          { required: true, message: "请选择专利类型", trigger: "change" }
        ],
        patent_code: [
          { required: true, message: "请选输入专利号", trigger: "blur" }
        ],
        name: [{ required: true, message: "请输入专利名称", trigger: "blur" }],
        patent_date_app: [
          { required: true, message: "请选择申请日期", trigger: "change" }
        ],
        // percents: [
        //   { required: true, message: "请选择费减比例", trigger: "change" }
        // ],
        // years: [{ required: true, message: "请选择费减至", trigger: "change" }],
        // annual_fee_by_time: [
        //   { required: true, message: "请选择年费已缴至哪一年", trigger: "change" }
        // ],
        // cost_deduction_time:[
        //   { required: true, message: "费减受理日", trigger: "change" }
        // ],
      }
    };
  },
  computed: {
    sum() {
      let num = this.form.service_id == 3 ? 20 : 10;
      this.form.years = "";
      this.form.percents = "";
      return num;
    }
  }
};
</script>

<style scoped>
.el-input {
  height: 32px;
  width: 200px;
}
.el-card >>> .el-input__inner {
  height: 32px;
}
.el-select {
  width: 200px;
}
.add {
  background-color: #f2f3f5;
}
.w-320 {
  width: 320px;
}
.additem-title {
  border-bottom: 1px solid #e6e6e6;
}
.additem-title span {
  font-weight: 700;
  display: inline-block;
  border-bottom: 3px solid #2962ff;
  padding: 10px;
}
.el-card >>> .el-form-item__label {
  color: #000;
}
.el-card >>> .el-button:focus,
.el-button:hover {
  color: #fff;
  border-color: #2962ff;
  background-color: #2962ff;
}
.gd{
  display: flex;
  flex-direction: column
}
</style>